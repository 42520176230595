import { consultationService } from '~/services/consultation';
import { Consultation } from '~/models/consultation';

export const useConsultationState = () => ({
	consultationState: useState<Consultation.Model[]>('consultationState', () => []),
	consultationLoaded: useState<boolean>('consultationLoaded', () => false),
	consultationPending: useState<boolean>('consultationPending', () => true),
});

export const useConsultation = () => {
	const { consultationState, consultationLoaded, consultationPending } = useConsultationState();

	/**
	 * Fetch consultation
	 */
	const fetchConsultation = async () => {
		if (consultationLoaded.value) return;

		consultationPending.value = true;

		consultationState.value = await consultationService.fetch();

		consultationLoaded.value = true;
		consultationPending.value = false;
	};

	/**
	 * Refresh consultation
	 */
	const refreshConsultation = async () => {
		consultationLoaded.value = false;

		await fetchConsultation();
	};

	return {
		consultationState,
		consultationPending,

		fetchConsultation,
		refreshConsultation,
	};
};
