import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';
import { Seo } from '~/models/seo';

export namespace Employee {
	export type Model = Common.Model & {
		alias: string;
		name: string;
		surname: string;
		skills: string[];
		photo: Image.Model;
		description: string;
		form: Form.Model | null;
		seo: Seo.Model | null;
	};

	export const modelState: Model = {
		...Common.modelState,
		alias: '',
		name: '',
		surname: '',
		skills: [],
		photo: Image.modelState,
		description: '',
		form: Form.modelState,
		seo: Seo.modelState,
	};
}
