import { employeesService } from '~/services/employees';
import { Employee } from '~/models/employee';

export const useEmployeesState = (limit: number | 'all') => ({
	employeesState: useState<Employee.Model[]>(
		limit ? `employeesState__${limit}` : 'employeesState',
		() => []
	),
	employeesLoaded: useState<boolean>(`employeesLoaded__${limit}`, () => false),
	employeesPending: useState<boolean>(`employeesPending__${limit}`, () => true),
});

export const useEmployees = (limit: number | undefined = undefined) => {
	const { employeesState, employeesLoaded, employeesPending } = useEmployeesState(limit || 'all');

	/**
	 * Fetch employees
	 */
	const fetchEmployees = async () => {
		if (employeesLoaded.value) return;

		employeesPending.value = true;

		employeesState.value = await employeesService.fetch(limit);

		employeesLoaded.value = true;
		employeesPending.value = false;
	};

	/**
	 * Refresh employees
	 */
	const refreshEmployees = async () => {
		employeesLoaded.value = false;

		await fetchEmployees();
	};

	return {
		employeesState,
		employeesPending,

		fetchEmployees,
		refreshEmployees,
	};
};

export const useEmployeeState = (alias: string) => ({
	employeeState: useState<Employee.Model>(`employeeState__${alias}`, () => Employee.modelState),
	employeeLoaded: useState<boolean>(`employeeLoaded__${alias}`, () => false),
	employeePending: useState<boolean>(`employeePending__${alias}`, () => true),
});

export const useEmployee = (alias: string) => {
	const { employeeState, employeeLoaded, employeePending } = useEmployeeState(alias);

	/**
	 * Get employee by alias
	 */
	const fetchEmployee = async () => {
		if (employeeLoaded.value) return;

		employeePending.value = true;

		employeeState.value = (await employeesService.getEmployee(alias)) || Employee.modelState;

		employeeLoaded.value = true;
		employeePending.value = false;
	};

	return {
		employeeState,
		employeePending,

		fetchEmployee,
	};
};
