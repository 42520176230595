<template lang="pug">
div(v-if='consultation', class='Consultation', :class='consultationTypeClass')
	template(v-if='consultation.type === Consultation.type.EMPLOYEES && greaterThan("tablet")')
		div(class='Employees')
			div(class='Employee', v-for='employee in employeesState', :key='employee.id')
				NuxtLink(
					:to='getEmployeePageUrlByAlias(employee.alias)',
					:title='`${employee.name} ${employee.surname}`'
				)
					UiImage(:image='employee.photo', width='100px', lazy)
	div(class='Title') {{ consultation.title }}
	div(v-if='lessThan("desktop")', class='Image')
		UiImage(:image='consultation.imageMobile', width='100px', lazy)
	div(v-else, class='Image')
		UiImage(:image='consultation.image', width='100%', lazy)
	div(class='Text', v-html='consultation.text')
	div(class='Buttons')
		UiButton(
			v-if='consultation.form',
			:size='greaterThan("mobileWide") ? "xl" : "md"',
			variant='primary',
			rounded,
			@click='getConsultationModal'
		) Записаться

		UiButton(
			v-if='consultation.hasWhatsapp && contacts?.whatsapp',
			:href='contacts.whatsapp',
			target='_blank',
			variant='whatsapp',
			theme='filled',
			:size='greaterThan("mobileWide") ? "xl" : "md"',
			iconLeft='social/whatsapp-inline',
			:iconLeftSize='greaterThan("mobileWide") ? "md" : "md"',
			rounded
		) Whatsapp
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { Consultation } from '~/models/consultation';

/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { contacts, fetchContacts } = useContacts();
const { consultationState, fetchConsultation } = useConsultation();
const { employeesState, fetchEmployees } = useEmployees(10);
const { modalState, modalShow } = useModal();
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Define props
 */
const { type } = defineProps<{
	type: (typeof Consultation.type)[keyof typeof Consultation.type];
}>();

/**
 * Consultation type class
 */
const consultationTypeClass = computed(() => {
	const classes = [
		{ type: Consultation.type.EMPLOYEES, class: 'Consultation--Type-Employees' },
		{ type: Consultation.type.VNZH, class: 'Consultation--Type-Vnzh' },
	];

	return classes.find(c => c.type === type)?.class || 'Consultation--Employees';
});

/**
 * Consultation by type
 */
const consultation = computed(
	() => consultationState.value.find(c => c.type === type) || undefined
);

/**
 * Get consultation modal
 */
const getConsultationModal = () => {
	if (!consultation.value?.form) return;

	modalState.value = {
		header: consultation.value.form.header,
		description: consultation.value.form.description,
		source: consultation.value.form.source,
		subject: consultation.value.form.subject,
		form: consultation.value.form.fields,
		target: consultation.value.form.target,
	};

	modalShow();
};

/**
 * Get employee url
 */
const getEmployeePageUrlByAlias = (alias: string) =>
	joinURL(getPageUrlByType('employees') || '', alias);

/**
 * Define fetch
 */
await fetchContacts();
await fetchConsultation();
await fetchResolver();
await fetchEmployees();
</script>

<style lang="less" scoped>
.Consultation {
	padding: 2.5rem 1.5rem;
	.relative(1; hidden);
	.box(grid; 100%; auto; @ColorWhite; 0 1rem; auto; 1fr 5rem; start; start);
	.border-radius(@BorderRadiusMedium);

	& > .Title {
		.grid(1 span);
		.text(@ColorBase; 1.25em 1.25rem @bold; left; uppercase none);
	}
	& > .Image {
		margin-top: -2.5rem;
		.grid(1 span);
		.box(block; unset; auto; none);
		.calc(width; ~'calc(100% + 1.5rem)');
		& > img {
			object-fit: cover;
			.box(block; 100%; auto);
		}
	}
	& > .Text {
		.grid(2 span);
		.text(@ColorBase; 1.6em 0.75rem @regular; left; none none);
	}
	& > .Buttons {
		margin: 1.5rem 0 0 0;
		.grid(2 span);
		.box(grid; 100%; auto; none; 1rem; 1fr; repeat(2; 1fr); stretch; center; column);
		& > .UiButton {
			.box(100%; 100%);
		}
	}

	.Employees {
		padding: 0 0 5rem 0;
		.relative(1);
		.grid(1 4; 1 2);
		.box(block; 100%; 100%; none);
		& > .Employee {
			.box(block; 4rem; 4rem; fade(@ColorBase, 15%));
			.border-radius(@BorderRadiusFull);
			.box-shadow(0 0 0 0 fade(@ColorBase, 25%));
			.transition(all; 250ms; 'sine');
			&:hover {
				.translateY(-0.1875rem);
				.box-shadow(0 1.5rem 3rem -1.5rem fade(@ColorBase, 25%));
			}
			& > a {
				.box(block; 100%; 100%);
				& > img {
					object-fit: cover;
					object-position: center 0.5em;
					.box(block; 100%; 100%);
				}
			}
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(8),
			&:nth-child(10) {
				.box(block; 7rem; 7rem);
			}

			&:nth-child(1) {
				.absolute(1; hidden; 0 none none -3rem);
			}
			&:nth-child(2) {
				.absolute(1; hidden; -3rem none none 35%);
			}
			&:nth-child(3) {
				.absolute(1; hidden; -2rem 10% none none);
			}
			&:nth-child(4) {
				.absolute(1; hidden; 15% none none 14%);
			}
			&:nth-child(5) {
				.absolute(1; hidden; 38% none none 50%);
			}
			&:nth-child(6) {
				.absolute(1; hidden; 45% 5% none none);
			}
			&:nth-child(7) {
				.absolute(1; hidden; 55% none none -3rem);
			}
			&:nth-child(8) {
				.absolute(1; hidden; none none -3rem 5%);
			}
			&:nth-child(9) {
				.absolute(1; hidden; none none 2.5% 40%);
			}
			&:nth-child(10) {
				.absolute(1; hidden; none 10% -4rem none);
			}
			@media all and (min-width: @desktop) {
				&:nth-child(1) {
					.absolute(1; hidden; -2rem none none -3rem);
				}
				&:nth-child(2) {
					.absolute(1; hidden; -5rem none none 35%);
				}
				&:nth-child(3) {
					.absolute(1; hidden; -4rem 10% none none);
				}
				&:nth-child(8) {
					.absolute(1; hidden; none none -5rem 5%);
				}
				&:nth-child(9) {
					.absolute(1; hidden; none none 0 40%);
				}
				&:nth-child(10) {
					.absolute(1; hidden; none 10% -7rem none);
				}
			}
		}
	}

	@media all and (min-width: @tablet-small) {
		& > .Buttons {
			.box(grid; auto; auto; none; 1rem; 1fr; auto; center; center; column);
		}
	}

	@media all and (min-width: @mobile) and (max-width: (@tablet - 1px)) {
		& > .Title {
			.text(@ColorBase; 1.25em 1.5rem @bold; left; uppercase none);
		}
		& > .Text {
			.text(@ColorBase; 1.6em 0.875rem @regular; left; none none);
		}
	}

	@media all and (min-width: @tablet) and (max-width: (@laptop - 1px)) {
		& > .Title {
			.text(@ColorBase; 1.25em 2rem @bold; left; uppercase none);
		}
		& > .Text {
			.text(@ColorBase; 1.6em 1rem @regular; left; none none);
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; @ColorWhite; 0 1rem; auto; 1fr 1fr; center; start);

		&--Type-Employees {
			padding: 6rem 5.25rem;
			.box(grid; 100%; auto; @ColorWhite; 0 1rem; auto; 1.25fr 1fr; center; start);

			& > .Title {
				.relative(2);
				.grid(1 2; 2 3);
				.text(@ColorBase; 1.25em 2.5rem @bold; left; uppercase none);
			}
			& > .Image {
				.absolute(1; hidden; none 0 0);
				.box(block; 20%; auto);
			}
			& > .Text {
				.relative(2);
				.grid(2 3; 2 3);
				.text(@ColorBase; 1.6em 1rem @regular; left; none none);
			}
			& > .Buttons {
				.relative(2);
				.grid(3 4; 2 3);
			}
		}
		&--Type-Vnzh {
			& > .Title {
				.grid(1 2; 1 2);
				.text(@ColorBase; 1.25em 2.5rem @bold; left; uppercase none);
			}
			& > .Image {
				.absolute(1; visible; 2.5rem 0 0);
				.box(flex; 50%; auto; none; flex-start; center; center; nowrap row);
				& > img {
					object-fit: cover;
					.box(block; auto; 110%);
				}
			}
			& > .Text {
				.grid(2 3; 1 2);
				.text(@ColorBase; 1.6em 1rem @regular; left; none none);
			}
			& > .Buttons {
				.grid(3 4; 1 2);
			}
		}
	}

	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; @ColorWhite; 0 4rem; auto; 1fr 1fr; center; start);
		&--Type-Employees {
			padding: 10rem 5.25rem;
		}
		&--Type-Vnzh {
			padding: 7rem 5.25rem;
		}
	}
}
</style>
