export namespace Image {
	export type Model = {
		id: string | undefined;
		charset: string | null;
		description: string | null;
		duration: number | null;
		embed: boolean | null;
		filename_disk: string | null;
		filename_download: string | null;
		filesize: number;
		folder: string | null;
		location: string | null;
		metadata: Record<string, unknown>;
		modified_by: string | null;
		modified_on: string | null;
		storage: string | null;
		tags: string[] | null;
		title: string | null;
		type: string | null;
		uploaded_by: string | null;
		uploaded_on: string | null;
		width: number;
		height: number;
	};

	export const modelState: Image.Model = {
		id: undefined,
		charset: null,
		description: null,
		duration: null,
		embed: null,
		filename_disk: null,
		filename_download: null,
		filesize: 0,
		folder: null,
		location: null,
		metadata: {},
		modified_by: null,
		modified_on: null,
		storage: null,
		tags: null,
		title: null,
		type: null,
		uploaded_by: null,
		uploaded_on: null,
		width: 0,
		height: 0,
	};
}
