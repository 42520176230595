import { Common } from '~/models/common';

export namespace Form {
	export const fields = {
		NAME: 'name',
		EMAIL: 'email',
		PHONE: 'phone',
	} as const;

	export type Field = (typeof fields)[keyof typeof fields];

	export type Model = Common.Model & {
		name: string;
		header: string;
		description: string;
		source: string;
		subject: string;
		fields: Field[];
		target: string | null;
	};

	export const modelState: Model = {
		...Common.modelState,
		name: '',
		header: 'Оставьте заявку',
		description: 'Заполните форму и мы свяжемся с вами в ближайшее время.',
		source: 'Неизвестный источник',
		subject: '',
		fields: [fields.NAME, fields.PHONE],
		target: '',
	};
}
