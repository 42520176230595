import { resolverService } from '~/services/resolver';
import { Resolver } from '~/models/resolver';

export const useResolverState = () => ({
	resolverState: useState<Resolver.Model[]>('resolverState', () => []),
	resolverLoaded: useState<boolean>('resolverLoaded', () => false),
	resolverPending: useState<boolean>('resolverPending', () => true),
});

export const useResolver = () => {
	const { resolverState, resolverLoaded, resolverPending } = useResolverState();

	/**
	 * Fetch resolver
	 */
	const fetchResolver = async () => {
		if (resolverLoaded.value) return;

		resolverPending.value = true;

		resolverState.value = await resolverService.fetch();

		resolverLoaded.value = true;
		resolverPending.value = false;
	};

	/**
	 * Refresh resolver
	 */
	const refreshResolver = async () => {
		resolverLoaded.value = false;

		await fetchResolver();
	};

	/**
	 * Get url by page type
	 */
	const getPageUrlByType = (pageType: Resolver.PageType) => {
		const page = resolverState.value.find(r => r.pageType === pageType);

		return page?.url;
	};

	return {
		resolverState,
		resolverPending,

		fetchResolver,
		refreshResolver,
		getPageUrlByType,
	};
};
