import { Common } from '~/models/common';
import type { Consultation } from '~/models/consultation';

const collection = 'consultation';

export const consultationService = {
	fetch: async (): Promise<Consultation.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Consultation.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'image.*', 'imageMobile.*', 'form.*'],
			},
		});
	},
};
