import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';

export namespace Consultation {
	export const type = {
		EMPLOYEES: 'employees',
		VNZH: 'vnzh',
	} as const;

	export type Model = Common.Model & {
		title: string;
		text: string;
		type: (typeof type)[keyof typeof type];
		image: Image.Model;
		imageMobile: Image.Model;
		hasWhatsapp: boolean;
		form: Form.Model;
	};

	export const modelState: Model = {
		...Common.modelState,
		title: '',
		text: '',
		type: type.EMPLOYEES,
		image: Image.modelState,
		imageMobile: Image.modelState,
		hasWhatsapp: true,
		form: Form.modelState,
	};
}
