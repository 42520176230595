import { Common } from '~/models/common';
import { Employee } from '~/models/employee';

const collection = 'employees';

export const employeesService = {
	fetch: async (limit: number | undefined = undefined): Promise<Employee.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Employee.Model>({
			collection,
			params: {
				limit,
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'photo.*', 'form.*', 'seo.*'],
			},
		});
	},

	getEmployee: async (alias: string): Promise<Employee.Model | undefined> => {
		const { getSingletonItem } = useDirectusItems();

		const [response] = await getSingletonItem<[Employee.Model]>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
					alias,
				},
				fields: ['*', 'photo.*', 'form.*', 'seo.*'],
			},
		});

		return response || undefined;
	},
};
